@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// @import "slick-carousel/slick/slick.css";
// @import "slick-carousel/slick/slick-theme.css";
//fa icons

// paths
// $img-path                            : "" !default; 
// typography
$main-font                              : "Poppins", sans-serif !default;
$secondary-font                         : "Inter", sans-serif !default;
$font-fa                                : "Font Awesome 5 Free" !default;
$font-lite                              : 300 !default;
$font-regular                           : 400 !default;
$font-medium                            : 500 !default;
$font-semibold                          : 600 !default;
$font-bold                              : 700 !default;
$font-extrabold                         : 800 !default;
$primary-color                          : #040612;
$primary-color-hover                    : #0000;
$primary-color-light                    : #010101;
$secondary-color                        : #2294E0;
$secondary-color-hover                  : #0000;
$secondary-color-light                  : #1B4399;
$bg-light                               : #D9D9D9;
$bg-dark                                : #040406;
$text-color                             : #979797;
$text-color-dark                        : #000000;
$text-color-light                       : #ffffff; 
$text-color-opt-1                       : #013859; 
$text-color-opt-2                       : #F7AF1D; 
$text-color-opt-3                       : #2C97E8; 
$text-color-opt-4                       : #FAFF00; 
$text-color-opt-5                       : #F0F0F0; 
/* Primary button */
$btn-primary-normal                     : #009444;
$btn-primary-hover                      : #709e32;
$btn-primary-disabled                   : #e8f4d9;
$btn-primary-default-text-color         : #fff;
$btn-primary-hover-text-color           : #fff;
$btn-primary-disabled-hover-text-color  : #709e32; 
/* Secondary button */
$btn-secondary-normal                   : #00AB9D;
$btn-secondary-hover                    : #00786e;
$btn-secondary-disabled                 : #cceeeb;
$btn-secondary-default-text-color       : #fff;
$btn-secondary-hover-text-color         : #fff;
$btn-secondary-disabled-hover-text-color: #00786e; 
/* Success button */
$btn-success-normal                     : #22CC88;
$btn-success-hover                      : #08BC7F;
$btn-success-disabled                   : #9CE8CA;
$btn-success-default-text-color         : #fff;
$btn-success-hover-text-color           : #fff;
$btn-success-disabled-hover-text-color  : #08BC7F; 
/* Info button */
$btn-info-normal                        : #02B1CD;
$btn-info-hover                         : #00A5C1;
$btn-info-disabled                      : #AAE5EE;
$btn-info-default-text-color            : #fff;
$btn-info-hover-text-color              : #fff;
$btn-info-disabled-hover-text-color     : #00A5C1; 
/* Warning button */
$btn-warning-normal                     : #F57920;
$btn-warning-hover                      : #BF6423;
$btn-warning-disabled                   : #FFD8BC;
$btn-warning-default-text-color         : #fff;
$btn-warning-hover-text-color           : #fff;
$btn-warning-disabled-hover-text-color  : #F57920; 
/* Danger button */
$btn-danger-normal                      : #EF5853;
$btn-danger-hover                       : #DB453F;
$btn-danger-disabled                    : #F3C1BF;
$btn-danger-default-text-color          : #fff;
$btn-danger-hover-text-color            : #fff;
$btn-danger-disabled-hover-text-color   : #DB453F; 
/* Light button */
$btn-light-normal                       : #f9f9f9;
$btn-light-hover                        : #FFFFFF;
$btn-light-disabled                     : #f6f6f6;
$btn-light-default-text-color           : #000;
$btn-light-hover-text-color             : #000;
$btn-light-disabled-hover-text-color    : #666; 
/* Dark button */
$btn-dark-normal                        : #222;
$btn-dark-hover                         : #000;
$btn-dark-disabled                      : #C9C9C9;
$btn-dark-default-text-color            : #fff;
$btn-dark-hover-text-color              : #fff;
$btn-dark-disabled-hover-text-color     : #222;